import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/images/fltr.png";
import { scrollToTop } from "../../lib/halper";
import {FaArrowUp, FaInstagram, FaReddit, FaTelegram, FaTwitter} from "react-icons/fa";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <FaArrowUp className="fas"/>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                     <img src={logoImage} alt="Full Throttle (FLTR Token)" />
                   </Link>
                  <div className="footer-content">
                    <p>
                      A new way to make the payments easy, reliable and 100%
                      secure.
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href={"https://www.reddit.com/r/fltrtokenofficial/"} aria-label={"Full Throttle Token Reddit"} target={"_blank"} rel="noreferrer"><FaReddit className={"fab"}></FaReddit> </a>
                      </li>
                      <li>
                        <a href="https://t.me/fullthrottleofficial" aria-label={"Full Throttle Token Telegram"} target={"_blank"} rel="noreferrer">
                          <FaTelegram className="fab"/>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/fltrtoken" aria-label={"Full Throttle Token Twitter"} target={"_blank"} rel="noreferrer">
                          <FaTwitter className="fab"/>
                        </a>
                      </li>
                        <li>
                        <a href="https://www.instagram.com/fullthrottletoken/" aria-label={"Full Throttle Token Instagram"} target={"_blank"} rel="noreferrer">
                          <FaInstagram className="fab"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de" target={"_blank"} rel="noreferrer" aria-label={"Buy FLTR Full Throttle Token"}>Buy on PancakeSwap</Link>
                      </li>
                      <li>
                        <Link to="https://bscscan.com/address/0x09824592081B87924b1B8371a747edfd369c06de" target={"_blank"} rel="noreferrer" aria-label={"Full Throttle Token On Bsc Scan"}>Explore on BscScan</Link>
                      </li>
                      <li>
                        <Link to="https://coinmarketcap.com/dexscan/bsc/0x8257f9ba2daccb85227b2d1593bc04d88f0c2f55/" rel="noreferrer" aria-label={"Full Throttle Token on Coinmarketcap"}>Details on CoinMarketCap</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        Telegram <a href={"https://t.me/fullthrottleofficial"} target={"_blank"} aria-label={"Full Throttle Token Telegram"} rel="noreferrer">@FullThrottleOfficial</a>
                      </li>
                      <li>
                        <Link to="mailto:hello@fltr.co.in" target={"_blank"} aria-label={"Contact Full Throttle Token"} rel="noreferrer">hello@fltr.co.in</Link>
                      </li>
                        <li>
                            <Link to={"https://www.instagram.com/fullthrottletoken/"} target={"_blank"} aria-label={"Full Throttle Token Instagram"} rel="noreferrer">Fltr Token Instagram</Link>
                        </li>
                      {/*<li>
                        <Link to="/">Suggestions</Link>
                      </li>
                      <li>
                        <Link to="/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/">Newsletters</Link>
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Join Telegram Community</h4>
                  <div className="footer-newsletter">
                    <p>
                      Discover FLTR Token's exciting world of digital content! Stay updated with the latest news, partnerships, and features. Engage with the community, join events, and enjoy exclusive promotions. Let's FLTR the future together!
                    </p>
                    <h3><Link to={"https://t.me/fullthrottleofficial"} target={"_blank"} aria-label={"FLTR Token Telegram"} rel="noreferrer">@FullThrottleOfficial</Link> </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. All Rights Reserved Full Throttle (FLTR) Token</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/Termsofconditions">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacypolicy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de" target={"_blank"} aria-label={"Buy Full Throttle Token"}>Buy FLTR Token</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
