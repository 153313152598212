import React from "react";
import img01 from "../../assets/img/profile/profile01_sm.png";
import img02 from "../../assets/img/profile/profile02_sm.png";
import img03 from "../../assets/img/profile/profile03_sm.png";
import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Leonard Token",
      designation: "Founder & CO",
    },
    {
      src: img02,
      name: "Joe In",
      designation: "Head of Design",
    },
    {
      src: img03,
      name: "Bessie Cooper",
      designation: "Vp People",
    }
  ];

  return (
    <section className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">OUr team</span>
              <h2 className="title">
                The Leadership <br /> <span>Team</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
        <div className="row justify-content-end">
          <div className={"col-md-4"}></div>
          <div className={"col-md-4"}></div>
          <div className={"col-md-4"}><a href={"https://opensea.io/collection/funko-frenzy"} target={"_blank"} rel={"noreferrer nofollow"} className={"small"}>Avatars from Funko Frenzy on Open Sea</a></div>
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
