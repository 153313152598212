import React from "react";
import img01 from "../../assets/img/images/about_img01_1.png";
//import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
             {/*  <img src={img02} alt="" className="img-two" />
 */}            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  Ready for an Adventure-Filled Digital Journey with <span>FLTR Token</span>?

                </h2>
              </div>
              <p>
                Come on board, enjoy thrilling digital shopping with FLTR Token, and earn fun-filled rewards with every unique click!
              </p>
              <Link to="https://pancakeswap.finance/swap?inputCurrency=0x09824592081B87924b1B8371a747edfd369c06de&outputCurrency=BNB" className="btn" target={"_blank"}>
                Purchase Tokens
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
