import React from "react";
import Headers from "../components/Header/HeaderOne"
const TermsOfConditions = () => {
    const root =`<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
    <!--
    .WordSection1{padding: 25px;}
    .MsoNormal>span{
        color:beige
     }
     /* Font Definitions */
     .MsoTitle{
        text-align: center;
     }    
     @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
   
        {margin:0cm;
        
        line-height:115%;
        font-size:11.0pt;
        font-family:"Arial",sans-serif;}
     
    p.MsoTitle, li.MsoTitle, div.MsoTitle
        {margin-top:0cm;
        margin-right:0cm;
        margin-bottom:3.0pt;
        margin-left:0cm;
        color: beige;
        line-height:115%;
        page-break-after:avoid;
        font-size:26.0pt;
        font-family:"Arial",sans-serif;}
    .MsoChpDefault
        {font-family:"Arial",sans-serif;}
    .MsoPapDefault
        {line-height:115%;}
    @page WordSection1
        {size:612.0pt 792.0pt;
        margin:72.0pt 72.0pt 72.0pt 72.0pt;}
    div.WordSection1
        {page:WordSection1;}
    -->

    </style>
    
    </head>
    
    <body lang=TR style='word-wrap:break-word'>
    
    <div id="WordSection1" class=WordSection1>
    
    <p class=MsoTitle><a name="_9byx58nlcs96"></a><span lang=EN>TERMS &amp;
    CONDITIONS OF SERVICE</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Last updated: Aug, 2023</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Welcome to Full Throttle (FLTR) Token
    (&quot;FLTR Token&quot;)! These terms and conditions are entered into between
    Full Throttle is private company limited by shares, its affiliates, and
    subsidiaries (&quot;Full Throttle Global&quot;, &quot;we&quot;, &quot;us&quot;,
    or &quot;our&quot;) and you or the legal entity you represent (&quot;you&quot;
    or &quot;your&quot;).</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>By using the Services, whether through the
    Full Throttle mobile application (&quot;Full Throttle App&quot;), or any other
    associated websites (collectively, the &quot;Site&quot;), application
    programming interfaces, or any of our other desktop or mobile applications
    (collectively, the &quot;Services&quot;), you:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. acknowledge that you have read these terms
    and conditions of service, our Privacy Policy located at link (&quot;Privacy
    Policy&quot;), and all obligations and rules that may be included within each
    Competition (as defined in these Terms) in which you participate
    (&quot;Rules&quot;) (these terms and conditions of service, the terms of any
    policy incorporated herein, and the Rules are collectively referred to as the
    &quot;Terms&quot;) in their entirety;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. agree to be bound by the Terms;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. are authorized and able to accept these
    Terms. If you do not wish to be bound by the Terms, do not click &quot;I
    accept&quot; and do not register with FLTR or otherwise use the Services.
    Declining to accept these Terms means you will be unable to participate in use
    the Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>THIS AGREEMENT CONTAINS AN ARBITRATION
    PROVISION IN SECTION 17. YOU AGREE AND UNDERSTAND THAT IF WE CANNOT RESOLVE A
    DISPUTE THROUGH OUR OPERATIONS TEAM, ALL DISPUTES ARISING UNDER THESE TERMS
    SHALL BE SETTLED IN BINDING ARBITRATION. YOU ALSO AGREE AND UNDERSTAND THAT
    ENTERING INTO THIS AGREEMENT CONSTITUTES A WAIVER OF YOUR RIGHT TO A TRIAL BY
    JURY AND PARTICIPATION IN A CLASS ACTION LAWSUIT.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. GENERAL USE</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1.1 Changes to the Terms</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We may amend, change, modify, or revise the
    Terms at any time, and we may post a notice on the Site or when you use the
    Services, and you can see when these Terms were last revised by referring to
    the &quot;Last Updated&quot; legend above. Your continued use of Services means
    you accept any new or modified Terms. You are responsible for reviewing the
    Terms for any changes, so please check back here from time to time.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1.2 Eligibility</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>By accepting these Terms you represent and
    agree that you:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. are a natural person who is at least 18
    years of age or the age of majority in your jurisdiction, and who is personally
    assigned to the phone number or email address submitted during your
    registration of an Account, if you have registered an Account;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. have the power to enter into a contract
    with Full Throttle Global;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. are physically located in a jurisdiction in
    which participation in any Service you use is permitted and unrestricted by
    that state or country�s laws;</span></p>
    
    <p class=MsoNormal><span lang=EN>4. at all times will abide by these Terms. If
    any one of these requirements is not met at any time, we may suspend or close
    your Account with or without notice. If you believe that a child under 18 or
    the age of majority in your jurisdiction has gained access to the Services,
    please contact us at hello@fltr.co.in; We have taken commercially reasonable
    steps to restrict use of Services to those who are at least years old or the
    age of majority in their jurisdiction. We do not sell products or services for
    purchase by minors. In the event that you are under the age of 18, you must
    have your parent or guardian�s consent to this Agreement, and Full Throttle
    Global may require them to enter into this Agreement on your behalf based on
    your jurisdiction.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. ACCOUNTS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2.1 Registration</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>To be eligible to access certain features of
    our Services, you may be required to register an account for Services
    associated with a verified identity approved in accordance with these terms
    (&quot;Account&quot;). When you create an Account, we may request certain personally
    identifiable information, including but not limited to your name and a
    legitimate email address that you control and to create a username and
    password. The password and username may be needed to access some of the
    Services. As a registered user, you can update your Account settings, including
    your email address, by logging into your Account inside of the Full Throttle
    App or Site by navigating to &quot;Account Settings&quot; from the home screen.
    Also, if you forget either your password or username, you can visit the Site or
    email us for help. Please keep your username and password secret because you
    are responsible for all activity taken through your Account. Although we may
    offer a feature that allows you to &quot;save&quot; or &quot;remember&quot;
    your password, this feature makes it possible for third parties to access your
    Account, so please use that feature prudently, because such use is at your own
    risk. We may, in our sole discretion, reject, change, suspend, and/or terminate
    an Account.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2.2 Your Account</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You, as the holder of your Account, are solely
    responsible for complying with these Terms, and only you are entitled to all
    benefits accruing thereto. You may not allow any other person to:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. access your Account;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. access Services through your Account;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. accept or use prizes, winnings, and other
    representatives of value (including without limitation digital trophies or
    virtual goods) (collectively &quot;Winnings&quot;). Your Account is not
    transferable to any other person or account. You must immediately notify us of
    any unauthorized use of your password or identification or any other breach or
    threatened breach of our security or the security of your Account.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2.3 Personal Location Data</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>As a part of our Services, we may collect
    location information from your device. If you would like to opt-out of having
    this information collected, you can disable location access to FLTR through the
    Settings menu on your mobile device.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2.4 Use of Information Collected</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>By upgrading an Account and providing an email
    address and phone number, users authorize FLTR to provide them with important
    announcements, relevant promotions, and other related communications. Users
    will always have the opportunity to opt-out of these communications at any
    time, and the use of such information is governed by our Privacy Policy.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>2.5 Access</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>To access our Services, you must have the
    necessary equipment (such as a smartphone or laptop) and the associated
    telecommunication service subscriptions to access the Internet. Our Services
    can be accessed directly using the Full Throttle App or the Site. Access to our
    Services may become degraded or unavailable during times of significant data
    congestion or in the event of a network failure. This could result in the
    inability to access some of our Services. Although we strive to provide you
    with excellent service, we do not represent that the Services will be available
    without interruption, and we do not guarantee that game matching and result
    submissions for Competitions (as defined below) will be successful in all
    circumstances. Full Throttle Global shall not be liable for any losses
    resulting from or arising out of transaction delays or a failure of network
    protocols.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. SERVICES AND SOFTWARE</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>3.1 General</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>3.1.1 Our Services</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>FLTR permits users to interact with and
    participate in skill-based games, online tournaments, and competitions
    (collectively, &quot;Competitions&quot;) with other FLTR users</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>4. COMPLIANCE WITH LAWS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>4.1 Prohibited US States/Countries</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You acknowledge that various rules,
    regulations, and laws addressing sweepstakes, contests, and tournaments with
    entry fees and/or prizes govern your participation in Competitions
    (&quot;Gaming Laws&quot;), and that Gaming Laws are set up by each individual
    US state, country, territory, or jurisdiction. Therefore, we DO NOT offer Token
    Competitions (as defined below) to users participating in Competitions in any
    jurisdiction in which such Competition violates its Gaming Laws
    (&quot;Prohibited Jurisdiction&quot;), and if you are located in any Prohibited
    Jurisdiction then you may not participate in Token Competitions. In the United
    States, Prohibited Jurisdictions, as of the &quot;Last Updated&quot; date
    above, include: Arizona, Arkansas, Connecticut, Delaware, Louisiana, Montana,
    New Mexico, South Carolina, South Dakota, and Tennessee. For card games,
    Prohibited Jurisdictions include Maine and Indiana. It is your responsibility
    to determine whether the state, country, territory, or jurisdiction in which
    you are located is a Prohibited Jurisdiction. We reserve the right (but have no
    obligation) to monitor the location from which you access Services, and we may
    block access from any Prohibited Jurisdiction.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>4.2 Additional Laws</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>In addition to Gaming Laws, you are also
    subject to all municipal, state, and federal laws, rules, and regulations of
    the city, state, and country in which you reside and from which you access and
    use Services, including without limitation U.S. securities laws, commodities
    laws, money transmission regulations, and sanction and export laws (together
    with Gaming Laws, the &quot;Applicable Laws&quot;). You are solely responsible
    for your compliance with all Applicable Laws. Access to some or all of the
    Competitions may not be legal for some or all residents of, or persons present
    in, certain jurisdictions.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>SERVICES AND COMPETITIONS ARE VOID WHERE
    PROHIBITED OR RESTRICTED BY APPLICABLE LAWS. YOUR PARTICIPATION IN COMPETITIONS
    IS AT YOUR OWN RISK, AND YOU AGREE NOT TO HOLD US RESPONSIBLE OR LIABLE IF
    APPLICABLE LAWS RESTRICT OR PROHIBIT YOUR ACCESS OR PARTICIPATION. WE MAKE NO
    REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE LAWFULNESS OF YOUR
    PARTICIPATING IN ANY COMPETITION OR USE OF SERVICES, NOR SHALL ANY PERSON
    AFFILIATED, OR CLAIMING AFFILIATION, WITH US HAVE AUTHORITY TO MAKE ANY SUCH
    REPRESENTATIONS OR WARRANTIES.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>5. REPRESENTATIONS, WARRANTIES, AND RISKS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>5.1 Risk of Regulatory Actions in One or More
    Jurisdictions</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You represent that you understand that the
    Services may be impacted by one or more regulatory inquiries or regulatory
    action, which could impede or limit our ability to continue to develop or offer
    some or all of the Services in your jurisdiction, or which could impede or
    limit your ability to access or use the Services, and that you understand that
    we shall not be liable to you in such events except for any requirement to
    provide you with a refund under applicable law.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>5.2 Authority; Compliance with Terms; Accuracy
    of Information</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You represent and warrant to us that:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. you have the right, authority, and capacity
    to agree to these Terms, to register for an Account, and to participate in
    those Competitions for which you register;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. you will comply with these Terms when
    participating in Competitions, receiving Services, and/or using Software;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. all information you supply to us is
    complete, accurate, and current (and knowingly submitting incomplete or
    inaccurate information, or failing to update and maintain current, complete,
    and accurate information, may result, without limitation, in immediate
    termination of your Account and forfeiture of Winnings.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>6. OUR INDEMNIFICATION OF US</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>6.1 Indemnification</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You will, at your own cost and expense,
    indemnify and hold us and our directors, officers, employees, and agents
    harmless from and against any and all claims, disputes, liabilities, judgments,
    settlements, actions, debts, or rights of action, losses of whatever kind, and
    all costs and fees, including reasonable legal and attorneys� fees, arising out
    of or relating to:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. your breach of these Terms;</span></p>
    
    <p class=MsoNormal><span lang=EN>2. any use of your Account, the Site, the
    Software, and the Services by any person, including yourself;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. your violation of Applicable Laws;</span></p>
    
    <p class=MsoNormal><span lang=EN>4. the breach or non-fulfillment of any
    representation and warranty or covenant made by you in these Terms;</span></p>
    
    <p class=MsoNormal><span lang=EN>5. your provision of false, incomplete, or
    misleading information (including, without limitation, citizenship, residency,
    nationality, or email address); and/or</span></p>
    
    <p class=MsoNormal><span lang=EN>6. your negligence, fraud, or misconduct.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Full Throttle Global reserves the right, at
    your expense, to assume the exclusive defense and control of any matter for
    which you are required to indemnify us, and you agree to cooperate with our
    defense of these claims. You agree not to settle any matter without the prior
    written consent of Full Throttle Global. Full Throttle Global will use
    reasonable efforts to notify you of any such claim, action, or proceeding upon
    becoming aware of it.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>7. PRIVACY</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>7.1 We are committed to your privacy, and our
    Privacy Policy, the terms of which are incorporated into these Terms as if set
    forth in their entirety, explains the policies put in place and used by us to
    protect your privacy as you visit the Site, participate in Competitions,
    download and use the Software, or otherwise use the Services. We receive,
    store, and use all information that you submit to the Site and all information
    you submit in registering for and participating in Services in accordance with
    the Privacy Policy.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>7.2 Using the Software and Services requires
    an Internet connection to our servers, and we may need to collect certain
    information from you and your Internet-enabled Device in order to make the
    Software</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>�and Services available to you, such as
    hardware system profile data, internet connection data, and any other data
    related to the operation of the Service from any Device that logs onto the
    Service using your Account. We will use this information in accordance with the
    Privacy Policy.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8. WINNINGS, ACCOUNT FUNDS, AND PAYMENTS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.1 Fees</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We may charge fees and payments for Services
    that you pay to participate in Competitions (&quot;Fees&quot;) and billing
    procedures are detailed on the Site, the Full Throttle App, or in the billing
    applications we use from time-to-time. If Fees are charged to your Account, you
    agree to pay those Fees. All Fees, stated and payable in GMEE Tokens, an ERC-20
    utility token based on Ethereum (&quot;Tokens&quot;), must be prepaid and are
    non-refundable. You are fully responsible and liable for all charges, deposits,
    and withdrawals made under your Account, including any unauthorized charges,
    deposits, or withdrawals. The price of Services may change at any time, but no
    price change will affect your past purchases. You agree to pay all processing
    fees and any transaction costs (e.g., standard gas fees) that Full Throttle
    Global may impose in connection with the Fees.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.2 Billing</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We may change Fees and billing procedures by
    updating our billing procedures with or without direct notice to you, but such
    changes will be reflected on the Site, the Full Throttle App, or the billing
    application in effect at that time. If you do not agree to the new Fees or
    billing procedures, you will not be able to continue using our Services. By
    providing a payment method, you:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>1. represent that you are authorized to use
    the payment method that you provided and that any payment information you
    provide is true and accurate</span></p>
    
    <p class=MsoNormal><span lang=EN>2. authorize us to charge you for the Services
    using your payment method;</span></p>
    
    <p class=MsoNormal><span lang=EN>3. authorize us to charge you for any paid
    feature of the Services that you choose to sign up for. We may bill you (a) in
    advance; (b) at the time of purchase; or (c) shortly after purchase, in our
    sole discretion as described when you access our Services. You must tell us
    within 60 days after an error first appears on your bill for an investigation
    of the charge to occur promptly. After 60 days from the first appearance of the
    error, we (i) will not be liable for any losses resulting from the error and
    (ii) will not be required to correct the error or provide a refund. If we
    identify a billing error, it will be corrected within 60 days. You agree to pay
    for all reasonable costs we incur to collect any past due amounts, including
    without limitation reasonable attorneys� fees and other legal fees and costs.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.3 Token Competitions</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you play games integrated in a Competition
    without depositing the Tokens into your Account for that Competition, then you
    are a &quot;Non-Token Player&quot; with respect to such Competition. However,
    if you play in a Competition that requires an entry fee paid in the Tokens
    (&quot;Token Competition&quot;), then you are a &quot;Token Player,&quot; and
    if you establish a positive Account balance for entry fees for Token
    Competitions, then you must submit and maintain at all times the following
    current and correct information: your full name, your phone number, your credit
    card, your wallet address, or other payment information. Participating in Token
    Competitions may require establishing a positive Account balance in any amount
    we determine. If you are a Token Player, by submitting this information, you
    consent to allowing us to share your personal and payment information in
    confidence with third-party service providers for the purposes of validating
    your identity and assessing the transaction risk associated with accepting your
    selected method of payment, and for any other purpose as detailed in our
    Privacy Policy.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.4 Non-Token Competitions</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you are a Non-Token Player, you will be
    required to use credits to play in non-Token Competitions (e.g., practice
    tournaments). In some cases, users may earn Tokens while playing Non-Token
    Competitions.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.5 Deposits</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Token Players will be able to purchase Tokens
    with a credit card, using Simplex (&quot;Simplex&quot;), a third-party service
    provider that processes crypto-to-credit card payments. Token Players will also
    be able to purchase Tokens on supported exchanges (e.g., Uniswap, MXC, Kucoin)
    and top up the Account from the Token Player�s blockchain wallet. Depositing
    and top-up of Tokens to the Account may be subject to transaction costs (e.g.,
    standard gas fees and credit card fees), and you agree to pay such transaction
    costs.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.6 Withdrawals</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you are a Token Player, you may request a
    withdrawal of funds, either in Tokens or in FIAT, from your available Account
    balance at any time. Processing of requested funds is made by Simplex or by
    refund to the payment method (e.g., the Token Player�s wallet) used to make
    your deposit and may take up to ninety (90) days; provided, however, that we
    may freeze your Account and/or delay a request for withdrawal of funds pending
    completion of any investigation of reported or suspected Abuse, verification of
    eligibility, or to comply with Applicable Laws. You agree to pay all withdrawal
    request processing fee and any transaction costs (e.g., standard gas fees) that
    Full Throttle Global may impose. When you withdraw funds from your account, you
    may be required to submit your social security number or other identifying
    information. Failure to provide your social security number or other requested
    identifying information at that time may result in inability to process your
    withdrawal for any winnings.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.7 AML/KYC</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Pursuant to any deposits or withdrawal of
    funds in accordance with Sections 8.5 and 8.6, you shall provide all
    documentation required and requested by Full Throttle Global to undertake and
    pass all due diligence, know-your-customer, and anti-money laundering checks
    (&quot;AML/CFT Checks&quot;). You agree and acknowledge that each deposit or
    withdrawal of funds in accordance with Sections 8.5 and 8.6 is subject to the
    requirement that you pass all AML/CFT Checks.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.8 Return of Funds Upon Account Closure</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you close your Account, funds in your
    Account will be returned subject to the terms of Section 8.6. If your Account
    is unilaterally closed by us or terminated for cause as allowed in these Terms,
    we will provide you with two (2) months� written notice via email and your
    Account on the Full Throttle App prior to the Account closure date. You shall
    withdraw all funds in your Account prior to the Account closure date. If you do
    not withdraw all funds in your Account prior to the Account closure date, any remaining
    funds in your Account will be subject to applicable laws regarding unclaimed
    monies.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.9 Inactive Accounts</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If your Account is inactive (i.e., you have</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>�not entered at least one (1) Competition) for
    twelve (12) consecutive months or more, we may, in our sole discretion, close
    your Account, and any remaining funds in your Account will be subject to
    applicable laws regarding unclaimed monies.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.10 Eligibility for Winnings</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you are eligible to receive Winnings, we
    may require that you provide proof that you are, or were at the time of your
    participation in the subject Competition, eligible to participate in accordance
    with these Terms and that your participation was in accordance with these
    Terms. If you do not provide such proof to our reasonable satisfaction, then
    you will not receive the relevant Winnings. If you receive a payment in error,
    we may reverse or require the return of the payment. You agree to cooperate
    with our efforts to do this. We may also reduce payment to you without notice to
    adjust for any previous overpayment.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.11 Credit Card/Simplex Use</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>When you pay for any charges by credit card,
    you represent to us that you are the authorized user of such credit card. You
    must promptly notify us of any changes to your credit card account number, its
    expiration date, and/or your billing address, or if your credit card expires or
    is canceled for any reason. We are not liable for any loss caused by any
    unauthorized use of your credit card or other method of payment by a third
    party (such as Simplex) in connection with the Services. Any attempt to defraud
    through the use of credit cards or other methods of payment, regardless of the
    outcome, or any failure by you to honor legitimate charges or requests for
    payment, will result in immediate termination of your Account, forfeiture of
    Winnings, and pursuit of civil litigation and/or criminal prosecution.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.12 Tax Matters</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>If you are a U.S. resident, we may send you an
    IRS Form W-9 and 1099-MISC or another appropriate form if your Winnings total US$600
    or more in any given calendar year. Depending on the state in which you reside,
    we may also send you additional federal or state tax forms. Without limiting
    the foregoing, we may withhold from your existing Account balance and/or from
    future Winnings any amount required to be withheld by Applicable Laws,
    including amounts due in connection with your failure to complete relevant tax
    documentation, but you remain solely responsible for paying all federal, state,
    and other taxes in accordance with all Applicable Laws.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>8.13 Refunds</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We do not grant any refund unless otherwise
    required by law.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>9. REFERRAL PROGRAM</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>9.1 Referral Fee</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Full Throttle Global will pay you a referral
    fee (&quot;Referral Fee&quot;) as set forth in FLTR�s referral program
    (&quot;Referral Program&quot;) and subject to the terms and conditions of the
    Referral Program.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>9.2 No Self-Play</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Referral Fees and the Referral Program will not
    include any user accounts that Full Throttle Global determines in its sole
    discretion to have been created directly by you or otherwise created to falsely
    generate or enhance Referral Fees.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>9.3 Taxes</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You are responsible for paying all taxes,
    tariffs, duties, or assessments arising out of the payment of the Referral Fees
    contemplated under these Terms and the Referral Program.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10. ACCEPTABLE USE POLICY</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10.1 Rules of Conduct</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You are personally responsible for your use of
    Services and Software, and while using Services and Software you must conduct
    yourself in a lawful and respectful manner in accordance with our rules of
    conduct below. We may temporarily or permanently ban users who violate these
    rules or abuse email communications, support communications, or the community
    purpose of any message board areas, as determined by us in our sole discretion.
    We reserve the right to disable a player�s ability to upload profile photos or
    edit their username at any time.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Service usernames will be displayed as �Your
    Name�; impersonating other players is not allowed.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Do not share personal information (your
    name, phone number, home address, and password) with other users.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Do not transmit or upload any copyrighted or
    trademarked materials in messages or postings.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Information disclosed in chat rooms, message
    boards, gameplay dialogue, or via electronic message is revealed to the public,
    and we are not responsible for information you choose to disclose to others.</span></p>
    
    <p class=MsoNormal><span lang=EN>- You may not commit fraud with regard to any
    Service.</span></p>
    
    <p class=MsoNormal><span lang=EN>- You may not attempt to impersonate or
    deceive another user for the purposes of illicitly obtaining private keys,
    passwords, account information, etc. (&quot;scamming&quot;).</span></p>
    
    <p class=MsoNormal><span lang=EN>- You may not make any commercial use of any
    of the information provided on the Website or through the Services nor make any
    use of the Website or Services for the benefit of a business.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10.2 Your Content</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You acknowledge that the Service is a passive
    conduit for user content, and we do not pre-screen user content or
    communications. We do not control, verify, or pay for any user content or
    communications. We do not endorse and specifically disclaim any responsibility
    or liability for any publicly posted content. In addition, we may terminate
    your access to any public forums at any time, without notice, for any reason
    whatsoever, and/or delete, move, or edit content submitted publicly, in whole
    or in part. You may only upload, send, and receive messages and material that
    is related to the subject matter of the public forums, complies with Applicable
    Laws, and conforms to any additional terms of service we post in the public
    forums. You may not upload to, distribute, or otherwise publish any content,
    information, or other material that:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Violates or infringes the copyrights,
    patents, trademarks, service marks, trade secrets, or other proprietary rights
    of any person.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Is libelous, threatening, defamatory,
    obscene, indecent, pornographic, or could give rise to any civil or criminal
    liability under U.S. or international law.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Includes any bugs, viruses, worms, trap
    doors, Trojan horses, or other harmful code or properties.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Submissions or opinions expressed by users are
    those of the individual expressing such submission or opinion and may not
    reflect our opinions. Subject to the foregoing, we may edit, refuse to post, or
    remove any information or materials that you submit to us, in our sole
    discretion. You may not use a false email address, pretend to be someone other
    than yourself, or otherwise mislead us or third parties as to the origin of
    your submissions or content. We may, but shall not be obligated to, remove or
    edit any of your submissions or content for any reason.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10.3 Cheating, Fraud, and Abuse</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>In accessing or participating in Services or
    using the Software, you represent and warrant to us that you will not engage in
    any activity that interrupts or attempts to interrupt the operation of the
    Services or Software. Anyone who engages in, participates in, or displays
    behavior that may be interpreted, in our sole discretion, as unfair methods in
    participating in Services or using the Software, including but not limited to,
    the use of unauthorized or altered software or hardware to assist play (e.g.,
    bots, bot nets, and collusion with bots), intentionally poor play in certain
    games to achieve a competitive advantage, collusion with other players (e.g.,
    intentionally losing rematches in Token Competitions), money laundering,
    harassment of other participants, posting objectionable material, breach of
    these Terms, breach of the security of your Account, or any other act (whether
    through the use of automated technology or otherwise) that unfairly alters your
    chance of winning or constitutes the commission of fraud (collectively,
    &quot;Abuse&quot;), you will be subject to immediate sanction (as determined by
    us in our sole discretion), which may include, without limitation:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Immediate termination of your Account and
    blocking of your access to the Site and Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Any Winnings that you may otherwise have
    been entitled to receive shall be void and forfeited.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Any Winnings received by you may be subject
    to disgorgement and/or recoupment.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>In addition to the foregoing, we reserve the
    right to disclose or report any money laundering or similar illegal activity to
    law enforcement and regulatory authorities. Without limiting our other
    available remedies, we may institute or seek any injunctive relief, civil
    and/or criminal proceedings against you and/or any of your co-conspirators
    arising out of or related to your commission of Abuse, including without
    limitation recovering all of our fees and expenses (including reasonable
    attorneys� fees) in connection with such efforts.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10.4 Hacking, Tampering, or Unauthorized
    Access</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Any attempt to gain unauthorized access to our
    systems or any other user�s account, interfere with procedures or performance
    of Services, Software, or the Website, or deliberately damage or undermine the
    Services or Software is subject to civil and/or criminal prosecution and will
    result in immediate termination of your Account and forfeiture of your
    Winnings. You acknowledge that we are not responsible for any damage, loss, or
    injury resulting from hacking, tampering, or other unauthorized access or use
    of Services or your Account.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>10.5 Restrictions</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Except with respect to open-source software
    FLTR has made available publicly, any use, reproduction, or redistribution of
    the Service, Software, or related products or services not expressly authorized
    by these Terms is expressly prohibited. You may not engage in, or assist others
    to engage in, conduct that would damage or impair our property including,
    without limitation:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Copying, distributing, transmitting,
    displaying, performing, framing, linking, hosting, caching, reproducing,
    publishing, licensing, or creating derivative works from any information,
    software, products, or services obtained from us.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Providing unauthorized means through which
    others may use Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Taking actions that impose an unreasonable
    or disproportionately large load on our or our suppliers� network
    infrastructure, or that could damage, disable, overburden, or impair our Site
    or Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Interfering with any other party�s use and
    enjoyment of Services and/or Software (including cheating) or the Site.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Attempting to gain unauthorized access to
    third party accounts</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>, the Service, or Software.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>11. COPYRIGHT COMPLAINTS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>11.1 DMCA</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>The Digital Millennium Copyright Act (DMCA)
    provides copyright owners who believe that their rights under the United States
    copyright law have been infringed by acts of third parties over the Internet
    with ways to protect their rights. If you believe that your copyrighted work
    has been copied without your authorization and is available in the Services in
    a way that may constitute copyright infringement, you can provide notice of
    your claim to the designated agent listed below. For your notice to be
    effective, it must include the following information:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- A physical or electronic signature of a
    person authorized to act on behalf of the owner of the intellectual property
    right that is allegedly infringed.</span></p>
    
    <p class=MsoNormal><span lang=EN>- A description of the copyrighted work that
    you claim has been infringed upon.</span></p>
    
    <p class=MsoNormal><span lang=EN>- A description of where the material that you
    claim is infringing is located.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Information reasonably sufficient to permit
    us to contact the complaining party, such as an address, telephone number, and,
    if available, an e-mail address at which the complaining party can be
    contacted.</span></p>
    
    <p class=MsoNormal><span lang=EN>- A statement by you that you have a
    good-faith belief that the disputed use is not authorized by the copyright
    owner, its agent, or the law.</span></p>
    
    <p class=MsoNormal><span lang=EN>- A statement that the information in the
    notification is accurate and, under penalty of perjury, that the complaining
    party is authorized to act on behalf of the owner of the exclusive right that
    is allegedly infringed.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Full Throttle Global�s Designated Agent can be
    reached at: hello@fltr.co.in.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12. PROPRIETARY RIGHTS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12.1 Your Content</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Subject to these Terms, you grant to us a
    worldwide, perpetual, unrestricted, royalty-free license to use, copy, modify,
    distribute, publish, perform, transmit, and display any and all communications,
    materials, content, and information that you submit to us, whether directly or
    through the Site, Services, or Software (&quot;Content&quot;), and waive any
    moral rights you may have in the Content. Subject to these Terms, any
    communication or material you transmit to us, including any data, questions,
    comments, suggestions, or the like, will be treated by us as non-confidential
    and non-proprietary. Subject to these Terms, we may use Content for any
    purpose, without any compensation, accounting, or other liability or obligation
    to you. If you use or share Content in a way that infringes others� copyrights,
    trademarks, other intellectual property rights, or privacy rights, you are
    breaching these Terms. You represent and warrant to us that for the duration of
    these Terms, you have (and will have) all the rights necessary for the Content
    you upload or share on the services and that the use of the Content, as
    contemplated in this Section, will not violate any Applicable Laws. If your
    Account is canceled or terminated, we may permanently delete your Content from
    our servers, and we have no obligation to return Content to you except to the
    extent provided under applicable law.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12.2 Ownership of Services; License to
    Services</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Excluding any open-source software (as further
    described in Section 12.3) or third-party software that the Software or the
    Services incorporate, as between you and Full Throttle Global, Full Throttle Global
    owns the Software and the Services, including all technology, content, and
    other materials used, displayed, or provided in the Software or in connection
    with the Services (including all intellectual property rights subsisting
    therein), and hereby grants you a limited, revocable, non-transferable, license
    to access and use those portions of the Software and the Services that are
    proprietary to Full Throttle Global.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12.3 FLTR License; Open Source Software
    License; Limitations</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Portions of the Software and the Services are
    governed by applicable licensing terms for the Software and the Services in
    these Terms (collectively, the &quot;FLTR License&quot;). You acknowledge that
    the Software or the Services may use, incorporate, or link to certain
    open-source components and that your use of the Software or Services is subject
    to, and you will comply with any, applicable open-source licenses that govern
    any such open-source components (collectively, &quot;Open-Source
    Licenses&quot;). Without limiting the generality of the foregoing, you may not:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Resell, lease, lend, share, distribute, or
    otherwise permit any third party to use the Software or the Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Use the Software or the Services for
    time-sharing or service bureau purposes.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Otherwise use the Software or the Services
    in a manner that violates the FLTR License or any other Open-Source Licenses.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12.4 Trademarks</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Any of Full Throttle Global�s product or
    service names, logos, and other marks used on the Site or as a part of the
    Services, including Full Throttle Global�s name and logo, are trademarks owned
    by Full Throttle Global, its affiliates, or its applicable licensors. You may
    not copy, imitate, or use them without Full Throttle Global�s (or the
    applicable licensor�s) prior written consent.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>12.5 Digital Assets</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Some Services and Software may allow you to
    create digital objects, such as avatars. Such digital objects, in addition to
    any digital or virtual objects or assets we assign to your Account, are
    collectively referred to as &quot;Digital Assets&quot;. You acknowledge that
    because all Digital Assets are created through the Software and/or Services, we
    solely and exclusively own all Digital Assets. To the extent we do not automatically
    own any Digital Asset, you hereby irrevocably, expressly, and automatically
    assign to us, in perpetuity, all right, title, and interest in and to any such
    Digital Assets, including, without limitation, all copyrights, patent rights,
    trade secrets, trademarks, moral rights, and all other applicable proprietary
    and intellectual property rights throughout the world. If you have any rights
    to Digital Assets that cannot (as a matter of law) be assigned to us in
    accordance with the foregoing, you unconditionally and irrevocably:</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>- Waive the enforcement of such rights against
    us.</span></p>
    
    <p class=MsoNormal><span lang=EN>- Grant to us an exclusive, irrevocable,
    perpetual, worldwide, royalty-free license (a) to reproduce, create derivative
    works of, distribute, publicly perform, publicly display, digitally perform,
    and otherwise use and exploit such Digital Assets, (b) to use, make, have made,
    sell, offer to sell, import, and otherwise exploit any product or service based
    on, embodying, incorporating, or derived from Digital Assets, and (c) to
    exercise any and all other present or future rights not yet known in Digital
    Assets.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Subject to these Terms, we</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>�grant you a limited license to use Digital
    Assets through your own Account solely for purposes and in furtherance of your
    use of Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>13. THIRD-PARTY LINKS &amp; ADS; OTHER USERS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>13.1 Third-Party Links &amp; Ads</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>The Site or Service may provide links to
    third-party websites and services and/or display advertisements for third
    parties (collectively, &quot;Third-Party Links &amp; Ads&quot;). Such
    Third-Party Links &amp; Ads are not under the control of Full Throttle Global,
    and Full Throttle Global is not responsible for any Third-Party Links &amp;
    Ads. Full Throttle Global provides access to these Third-Party Links &amp; Ads
    only as a convenience to you and does not review, approve, monitor, endorse,
    warrant, or make any representations with respect to Third-Party Links &amp;
    Ads. You use all Third-Party Links &amp; Ads at your own risk and should apply
    a suitable level of caution and discretion in doing so. When you click on any
    of the Third-Party Links &amp; Ads, the applicable third party�s terms and
    policies apply, including the third party�s privacy and data gathering
    practices. You should make whatever investigation you feel necessary or
    appropriate before proceeding with any transaction in connection with such
    Third-Party Links &amp; Ads.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>14. TERM AND TERMINATION</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>14.1 Term and Termination</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>These Terms apply to you and to us from the
    date that you accept them as provided above until termination of your use of
    the Services (whether by deactivation, cancellation, closure, expiration, or
    termination of your Account by you or us or otherwise). You may terminate these
    Terms at any time and for any reason by going to your Account webpage and
    following the account closure process. Upon termination of your Account, you
    must immediately discontinue use of the Services and the Software and your
    Account. Immediately upon termination of your Account, all licenses and rights
    granted to you under these Terms automatically terminate. Any and all terms and
    conditions within these Terms that should, by their nature, survive termination
    of these Terms will survive such termination (including, without limitation,
    Sections 6-10, 12, 14, and 14-18).</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>15. DISCLOSURES; DISCLAIMERS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>15.1 GENERAL DISCLAIMER</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>TO THE MAXIMUM EXTENT PERMITTED UNDER
    APPLICABLE LAW, ALL SERVICES, PRODUCTS, INFORMATION, AND DATA PROVIDED OR MADE
    AVAILABLE BY US (INCLUDING WITHOUT LIMITATION SOFTWARE) ARE &quot;AS IS&quot;
    AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT
    NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE, OR NON- INFRINGEMENT, AND YOU ASSUME THE ENTIRE RISK WITH RESPECT
    THERETO. WE MAKE NO REPRESENTATION, WARRANTY, OR GUARANTEE THAT SERVICES, YOUR
    ACCOUNT, SOFTWARE, VIRTUAL GOODS, AND/OR THE SITE WILL BE SECURE, VIRUS-FREE,
    UNINTERRUPTED, OR ERROR-FREE, OR THAT THE SAME WILL FUNCTION PROPERLY IN
    COMBINATION WITH ANY THIRD-PARTY COMPONENT, TECHNOLOGY, HARDWARE, SOFTWARE, OR
    SYSTEM.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>15.2 Disruptions and Outages</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We strive to keep Services up and running;
    however, all online services suffer occasional disruptions and outages, we are
    not responsible or liable for any disruption or loss you may suffer as a
    result. You should regularly backup content that you store on the Services.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>16. LIMITATIONS OF LIABILITY</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>16.1</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>TO THE MAXIMUM EXTENT PERMITTED UNDER
    APPLICABLE LAW, NEITHER WE, NOR OUR SUPPLIERS OR LICENSORS, WILL BE LIABLE TO
    YOU OR ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL
    (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST DATA, OR LOSS OF GOODWILL),
    OR INCIDENTAL DAMAGES, ARISING OUT OF OR RELATING TO THESE TERMS, THE WEBSITE,
    OR ANY INFORMATION, SERVICES, PRODUCTS, OR SOFTWARE MADE AVAILABLE OR
    ACCESSIBLE TO YOU, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT, WARRANTY,
    NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY
    OR CONTRIBUTION, OR OTHERWISE, EVEN IF WE OR OUR THIRD PARTY SUPPLIERS OR
    LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITY.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>16.2</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>TO THE MAXIMUM EXTENT PERMITTED UNDER
    APPLICABLE LAW, OUR MAXIMUM LIABILITY TO YOU ARISING OUT OF OR IN ANY WAY
    CONNECTED TO THESE TERMS SHALL NOT EXCEED US$50.00. THE EXISTENCE OF ONE OR
    MORE CLAIMS BY YOU WILL NOT INCREASE OUR LIABILITY. IN NO EVENT SHALL OUR
    SUPPLIERS OR LICENSORS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY
    CONNECTED TO OUR PRODUCTS, INFORMATION, OR SERVICES.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Certain jurisdictions do not allow limitations
    of liability for incidental, consequential, or certain other types of damages;
    as such, the limitations and exclusions set forth in this Section may not apply
    to you.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17. DISPUTE RESOLUTION AND ARBITRATION</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.1 Notification of Disputes</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>In the event that you experience issues with
    the Services, please contact us first! We strive to improve our services and
    want to address your concerns without resorting to formal legal proceedings.
    Before filing a claim, you agree to try to resolve the dispute informally by
    contacting Full Throttle Global first at hello@fltr.co.in.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.2 Agreement to Arbitrate</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You and Full Throttle Global agree to resolve
    any claims relating to these Terms (including any question regarding its
    existence, validity, termination, or any services or products provided and any
    representations made by us) through final and binding arbitration. You agree to
    first give us an opportunity to resolve any claims by contacting us as set
    forth in Section 17.1 above. If we are not able to resolve your claims within
    60 days of receiving the notice, you may seek relief through as set forth
    below.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.3 Arbitration Procedure</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Any dispute, controversy, difference, or claim
    arising out of or relating to these Terms, including the existence, validity,
    interpretation, performance, breach, or termination thereof or any dispute
    regarding non-contractual obligations arising out of or relating to it shall be
    referred to and finally resolved by arbitration administered by the Hong Kong
    International Arbitration Centre (&quot;HKIAC&quot;) under the HKIAC
    Administered Arbitration Rules in force when the Notice of Arbitration is
    submitted. The law of this arbitration clause shall be Hong Kong law. The seat
    of arbitration shall be Hong Kong. The number of arbitrators shall be one (1).
    The arbitration proceedings shall be conducted in English.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.4 Class Action Waiver</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>TO THE MAXIMUM EXTENT PERMITTED UNDER
    APPLICABLE LAW, ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE IN ANY FORUM
    WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS. NEITHER YOU NOR WE WILL SEEK
    TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION OR IN ANY OTHER PROCEEDING IN WHICH
    EITHER PARTY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. NO
    ARBITRATION OR PROCEEDING WILL BE COMBINED WITH ANOTHER WITHOUT THE PRIOR
    WRITTEN CONSENT OF ALL PARTIES TO ALL AFFECTED ARBITRATIONS OR PROCEEDINGS. IF
    THIS WAIVER IS FOUND TO BE ILLEGAL OR UNENFORCEABLE AS TO ALL OR SOME PARTS OF
    A DISPUTE, THEN IT WILL NOT APPLY TO SUCH PART. INSTEAD, THOSE PARTS SHALL BE
    SEVERED AND PROCEED IN A COURT OF LAW, WITH THE REMAINING PARTS PROCEEDING IN
    ARBITRATION.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.5 Filing Requirement</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Claims or disputes must be filed within one
    year. To the extent permitted by applicable law, any claim or dispute under
    these Terms must be filed within one year from the date of the cause of action.
    If a claim or dispute is not filed within one year, it shall be permanently
    barred.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.6 Arbitration Notice</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>To begin an arbitration proceeding, you must
    send a letter requesting arbitration and describing your claims to Full
    Throttle Global. If we request arbitration against you, we will give you notice
    at the email address you have provided.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.7 Controlling Law</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>These Terms, including any non-contractual
    obligations arising out of or in connection with it, shall be governed by and
    construed in accordance with the laws of Hong Kong.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>17.8 Equitable Relief</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You agree that we would be irreparably damaged
    if these Terms were not specifically enforced. Therefore, in addition to any
    other remedy we may have at law, and notwithstanding our agreement to arbitrate
    disputes, we are entitled without bond, other security, or proof of damages, to
    appropriate equitable remedies with respect to your violation of these Terms in
    any court of competent jurisdiction.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18. MISCELLANEOUS</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.1 Entire Agreement</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>These Terms constitute the entire agreement
    between you and us pertaining to the subject matter hereof and supersede all
    prior or other arrangements, understandings, negotiations, and discussions,
    whether oral or written. Our failure to require or enforce strict performance
    by you of any provision of these Terms or to exercise any right under them
    shall not be construed as a waiver or relinquishment of our right to assert or
    rely upon any such provision or right in that or any other instance. Section
    titles in these Terms are for reference only and have no legal effect.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.2 Severability</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>The provisions of these Terms are intended to
    be severable. If for any reason any provision of these Terms shall be held
    invalid or unenforceable in whole or in part by any court of competent
    jurisdiction, such provision shall, as to such jurisdiction, be ineffective to
    the extent of such determination of invalidity or unenforceability without
    affecting the validity or enforceability thereof in any other manner or jurisdiction
    and without affecting the remaining provisions of the Terms, which shall
    continue to be in full force and effect.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.3 Amendment and Modification</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>These Terms cannot be modified by you and may
    only be modified by us as provided above.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.4 Remedies</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>No right or remedy of ours shall be exclusive
    of any other, whether at law or in equity, including without limitation
    damages, injunctive relief, attorneys� fees, and expenses.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.5 Assignability</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>We may assign these Terms, in whole or in
    part, at any time without notice to you. You may not assign these Terms or
    transfer any rights to use the Services or Software.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.6 Consent to Notice</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>You consent to our providing you notifications
    about the Services or information the law requires us to provide via email to
    the address that you specified when you created your Account. Notices emailed
    to you will be deemed given and received when the email is sent. If you do not
    consent to receive notices electronically, you must close your Account.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>18.7 Successors and Assigns</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>These Terms are solely for your and our
    benefit, and not for the benefit of any other person, except for our successors
    and assigns.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>A printed version of these Terms and of any
    notice given in electronic form will be admissible in judicial or
    administrative proceedings based upon or relating to these Terms to the same
    extent and subject to the same conditions as other business documents and
    records originally generated and maintained in printed form.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>Please send any questions or comments
    (including all inquiries unrelated to copyright infringement) to:
    hello@fltr.co.in. Please print these Terms for your records.</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN>&nbsp;</span></p>
    
    </div>
    
    </body>
    
    </html>
    `;
    console.log(root)
    return (
        <>
        <Headers/>
      <div  dangerouslySetInnerHTML={{__html:root}}></div>
        </>
    )
}
export default TermsOfConditions