import React from "react";
import Headers from "../components/Header/HeaderOne"
const PrivacyPolicy = () => {

    return (
        <>
        <Headers/>
            <div style={{ padding: "25px ",}}>
                <h2 style={{ textAlign: "center" }} >Privacy Policy</h2>
                <h3>1. INTRODUCTION</h3>
                <h5>1.1</h5>
                <p>
                    FLTR Token, operated by Full Throttle Global private company limited by shares ("FLTR Token" or "we" or "our" or "us"), acknowledges and takes seriously its responsibilities under relevant privacy laws. We value the trust you place in us and are committed to properly managing, protecting, and processing your personal data.
                </p>

                <h5>1.2</h5>
                <p>This Personal Data Protection and Privacy Policy ("Privacy Policy") explains how FLTR Token collects and processes personal data, the purposes for which it is used, and how we handle, collect, use, disclose, and process your personal data.</p>
                <h5>1.3</h5>
                <p>If you provide personal data of any third party to FLTR Token, you must ensure that you are authorized to disclose such data, and the purposes for which you disclosed the data to the third party allow us to use it as described in this Privacy Policy.</p>
                <h3>2. DEFINITION OF DATA PROTECTION AND PRIVACY TERMS</h3>
                <h5>2.1</h5>
                <p>In the context of FLTR Token, "data" refers to any representation of information (including opinions) in any document, and includes personal identifiers.</p>
                <h5>2.2</h5>
                <p>"data processor" refers to a person who (i) processes personal data on behalf of another person, and (ii) does not process the data for any of their own purposes.</p>
                <h5>2.3</h5>
                <p>"personal data" encompasses any data (i) directly or indirectly related to a living individual, (ii) from which the identity of the individual can be directly or indirectly determined, and (iii) which is in a form that allows access to or processing of the data. Personal data can include factual information like name, address, or date of birth, as well as opinions or product reviews. It may also include an email address when used in combination with other identifiers, as long as the information pertains to the data subject and affects the individual's privacy.</p>
                <h5>2.4</h5>
                <p>"processing," concerning personal data, involves amending, augmenting, deleting, or rearranging the data, whether by automated means or otherwise.</p>
                <h3>3. TYPES OF PERSONAL DATA WE COLLECT</h3>
                <h5>3.1</h5>
                <p>We collect information about you when you register for an FLTR account ("Account") and use our website, mobile application, forms, surveys, and other channels during your interactions and use of our Services.</p>
                <h5>3.2</h5>
                <p>The personal data we may collect includes:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>Your personal information, such as your name, date of birth, nationality, passport number, gender, and marital status.</li>
                        <li style={{ listStyle: "outside" }}>Your contact information, including residential or postal addresses, email addresses, telephone numbers, and mobile phone numbers.</li>
                        <li style={{ listStyle: "outside" }}>Location data may be collected with your permission to access certain features. If you choose not to provide this permission, you can still use our Services, but some features may not be available.</li>
                        <li style={{ listStyle: "outside" }}>Communications exchanged between you and FLTR. While we may send you service-related emails, you can opt-out of such communications.</li>
                        <li style={{ listStyle: "outside" }}>Past and present employment information, such as organization details, job function, responsibilities, and contact information.</li>
                        <li style={{ listStyle: "outside" }}>For corporate users, beneficial owner information, authorized signatory details, and business contact information.</li>
                        <li style={{ listStyle: "outside" }}>Billing and payment details, including credit/debit cardholder name, card number, security code, and expiry date</li>
                        <li style={{ listStyle: "outside" }}>Proof of income, financial details, photographs, videos, and audio recordings collected through online or offline platforms.</li>
                    </ul>
                </div>
                <h5>3.3</h5>
                <p>When you visit our website or use our mobile application, certain information is automatically collected and stored, including:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>Internet Protocol (IP) address used to connect your device to the Internet.</li>
                        <li style={{ listStyle: "outside" }}>Browser type and version, operating system, and platform information.</li>
                        <li style={{ listStyle: "outside" }}>Unique reference numbers linked to the data you provide.</li>
                        <li style={{ listStyle: "outside" }}>Login details.</li>
                        <li style={{ listStyle: "outside" }}>Full URL clickstream data, including date and time.</li>
                        <li style={{ listStyle: "outside" }}>Cookie identifier.</li>
                        <li style={{ listStyle: "outside" }}>Your activity on our website or mobile application, such as pages visited, searches made, and Services purchased.</li>
                    </ul>
                </div>

                <h5>3.4</h5>
                <p>We may receive information about you from third parties if you choose to link your FLTR profile with your profile on other websites or social media platforms operated by third parties.</p>
                <h5>3.5</h5>
                <p>We only collect personal data that is reasonably necessary for the purposes stated in paragraph 5 of this Privacy Policy to provide our Services. We avoid collecting unnecessary or excessive personal data.</p>
                <h5>3.6</h5>
                <p>Regarding voluntary personal data you provide:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>We use the personal data provided solely for the purposes stated in paragraph 5 of this Privacy Policy.</li>
                        <li style={{ listStyle: "outside" }}>Any irrelevant personal data provided will be disregarded for the purposes stated in paragraph 5 of this Privacy Policy.</li>
                        <li style={{ listStyle: "outside" }}>We will not use the personal data for any other purposes unless you have given your explicit consent for those specific purposes.</li>
                    </ul>
                </div>

                <h3>
                    4. COOKIES
                </h3>
                <h5>4.1</h5>
                <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer or device.</p>
                <h5>4.2</h5>
                <p>We use cookies for the following purposes:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>To identify you from other users on our website, mobile application, or IT portal.</li>
                        <li style={{ listStyle: "outside" }}>To remember your preferences, such as language, font size, and color scheme, for a consistent experience during your future visits.</li>
                        <li style={{ listStyle: "outside" }}>To analyze your navigation patterns on our website and applications to optimize their design.</li>
                        <li style={{ listStyle: "outside" }}>To track your behavior and preferences, helping us understand the information and Services that may interest you.</li>
                    </ul>
                </div>
                <h5>4.3</h5>
                <p>You can block or deactivate cookies in your browser settings.</p>
                <h5>4.4</h5>
                <p>We use log-in cookies to remember your login information for a seamless experience.</p>
                <h5>4.5</h5>
                <p>Session cookies are used to track your movements from page to page and store your selected inputs, reducing the need to repeatedly provide the same information.</p>
                <h5>4.6</h5>
                <p>By continuing to use our website, mobile application, or IT portal, you consent to the use of cookies as described above. However, please note that we have no control over cookies used by third parties.</p>

                <h3>5. PURPOSES FOR WHICH THE PERSONAL DATA IS COLLECTED, USED AND DISCLOSED</h3>
                <h5>5.1</h5>
                <p>We collect, use, disclose, and process your personal data for various purposes, including but not limited to:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>Processing your FLTR Token account registration and managing your account with us.</li>
                        <li style={{ listStyle: "outside" }}>Providing you with our services and facilitating transactions related to FLTR Token.</li>
                        <li style={{ listStyle: "outside" }}>Notifying you of new game releases or updates.</li>
                        <li style={{ listStyle: "outside" }}>Responding to your inquiries and feedback via phone, email, or other communication channels.</li>
                        <li style={{ listStyle: "outside" }}>Conducting research, analysis, and development activities to improve our services and marketing programs.</li>
                        <li style={{ listStyle: "outside" }}>Carrying out marketing campaigns that you have consented to participate in.</li>
                        <li style={{ listStyle: "outside" }}>Administering and managing your FLTR Token account, including communication through various modes of contact.</li>
                        <li style={{ listStyle: "outside" }}>Performing due diligence or security checks as required by law or our risk management procedures.</li>
                        <li style={{ listStyle: "outside" }}>Investigating and preventing fraud, unlawful activities, or misconduct.</li>
                        <li style={{ listStyle: "outside" }}>Complying with legal and regulatory requirements, including government authorities' requests.</li>
                        <li style={{ listStyle: "outside" }}>Facilitating payment for FLTR Token-related goods and services.</li>
                        <li style={{ listStyle: "outside" }}>Performing internal administrative, operational, and technology tasks to manage your FLTR Token account.</li>
                        <li style={{ listStyle: "outside" }}>Producing statistics and research for reporting and record-keeping purposes.</li>
                        <li style={{ listStyle: "outside" }}>Disclosing data to third parties for legal compliance or court orders.</li>
                        <li style={{ listStyle: "outside" }}>Improving our services to enhance your experience with FLTR Token.</li>
                    </ul>
                </div>
                <h5>5.2</h5>
                <p>We may disclose your personal data to third parties, including banks, payment service providers, and data processors, both within and outside your jurisdiction, to fulfill the aforementioned purposes. By using FLTR Token, you consent to the disclosure and processing of your personal data by such third parties for the purposes mentioned above.</p>
                <h5>5.3</h5>
                <p>We will ensure that non-Hong Kong third parties to whom we disclose your personal data are bound by contractual obligations similar to those imposed by Hong Kong privacy laws.</p>
                <h5>5.4</h5>
                <p>We will not use your personal data for purposes other than those stated above unless you provide explicit consent.</p>
                <h5>5.5</h5>
                <p>We may obtain personal data about you from other sources and use it for the purposes mentioned above. Additionally, we may combine information received from various sources to enhance our services and marketing efforts.</p>
                <h5>5.6</h5>
                <p>We will seek your consent before sharing your personal data with any party not described in paragraph 5.2 of this Privacy Policy.</p>
                <h3>6. SECURITY AND RETENTION OF PERSONAL DATA</h3>
                <h5>6.1</h5>
                <p>The security of your personal data is of utmost importance to us. We take appropriate measures to safeguard your personal data from loss, unauthorized access, disclosure, alteration, or destruction. We employ the same safeguards for your data as we do for our proprietary information. All information provided to us is stored on secure servers, and payment transactions are encrypted using SSL technology. If you have a password to access specific parts of our website(s) or website/IT portal(s)/mobile application(s), it is crucial to keep it confidential and not share it with anyone.</p>
                <h5>6.2</h5>
                <p>We will ensure that your personal data is retained only for as long as necessary to fulfill the purposes for which it was collected, and when it is no longer required for any legal or business purposes, we will take steps to destroy or anonymize it.</p>
                <h5>6.3</h5>
                <p>If we entrust your personal data to data processors, we will monitor their compliance with this Privacy Policy through contractual and other means.</p>
                <h5>6.4</h5>
                <p>While we implement security measures to protect your personal data, it is essential to note that internet transmission is not entirely secure. We cannot guarantee the security of data transmitted through the internet, and any transmission is at your own risk.</p>

                <h3>7. RETENTION OF PERSONAL DATA</h3>
                <h5>7.1</h5>
                <p>We will retain your personal data for as long as your FLTR account is active and accessed.</p>
                <h5>7.2</h5>
                <p>If your FLTR account remains inactive for a period of twelve months or if we close your account ("End Date"), we will keep your personal data for seven years after the End Date. We may extend the retention period if required to comply with contractual or legal obligations, or if you have provided consent for us to retain it.</p>
                <h5>7.3</h5>
                <p>At the end of the retention period, we will ensure the secure deletion of your personal data, app-related data, and account-related information. Physical documents containing personal data will be shredded or otherwise destroyed to ensure confidential disposal.</p>
                <h5>7.4</h5>
                <p>Our data processors, to whom we disclose your personal data in compliance with this Privacy Policy, will retain your data only for the duration necessary to fulfill the purposes for which the data was disclosed, and will delete it when no longer required, unless prohibited by law or public interest.</p>

                <h3>8. DATA ACCESS AND CORRECTION</h3>
                <h5>8.1</h5>
                <p>You have the right to access and correct any personal data we hold about you, subject to applicable laws. To request a copy of your personal data or update and correct it, please contact our Data Protection Officer via email or in writing.</p>
                <h5>8.2</h5>
                <p>We may request sufficient information to verify your identity and the nature of your request before processing it. In certain cases, we reserve the right to charge a reasonable fee for data access requests.</p>
                <h5>8.3</h5>
                <p>Upon receipt of your data access request, we will endeavor to provide the relevant information within 40 days. If unable to respond within this timeframe, we will notify you of the expected response time.</p>
                <h5>8.4</h5>
                <p>Should we refuse a data access request under specific circumstances as per applicable privacy laws, we will provide written notice and reasons within 40 days.</p>
                <h5>8.5</h5>
                <p>For data correction requests, we will update your personal data within 40 days of receiving sufficient information. If unable to do so within this timeframe, we will inform you of the soonest possible correction date.</p>
                <h5>8.6</h5>
                <p>We may reject a correction request under prescribed circumstances, such as when we determine the data to be accurate or the proposed correction is inaccurate. In such cases, we will provide a written refusal with reasons.</p>
                <h5>8.7</h5>
                <p>You have the option to delete your FLTR account or account information through the account settings page in the FLTR app. Once your account is deleted, your data, including account activities and leaderboard standings, cannot be reinstated, unless legally required or justified.</p>
                <h3>9. LINK TO OTHER WEBSITES</h3>
                <p>FLTR's website, website/IT portal(s)/mobile application(s), and other digital and telecommunication channels may contain links to websites operated by third-party companies with different privacy practices. We advise you to exercise caution and review the privacy statements of these other sites. We have no control over the personal data you submit to or receive from these third parties. We bear no responsibility or liability for the content, activities, products, and services of these linked third-party websites.</p>
                <h3>10. DIRECT MARKETING</h3>
                <p>From time to time, we may conduct direct marketing of our Services through email or other forms of communication. We may use your personal data for direct marketing conducted by us, our business partners, or third-party merchants.</p>
                <p>The types of personal data used for direct marketing purposes are:</p>
                <div>
                    <ul style={{ padding: "15px" }}>
                        <li style={{ listStyle: "outside" }}>Your email address The direct marketing activities we may conduct using your personal data include:<ul>
                            <li style={{ listStyle: "inside" }}>Newsletters and updates on our blog</li>
                            <li style={{ listStyle: "inside" }}>Promotions of our website, Services, or applications</li>
                            <li style={{ listStyle: "inside" }}>Competitions or contests held by us</li>
                            <li style={{ listStyle: "inside" }}>Updates regarding our website, Services, or applications</li>
                            <li style={{ listStyle: "inside" }}>Surveys related to our website, Services, or applications</li>
                            <li style={{ listStyle: "inside" }}>Event invitations</li>
                            <li style={{ listStyle: "inside" }}>Festive greetings</li>
                            <li style={{ listStyle: "inside" }}>Advertisements of our website, Services, or applications</li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <p>If you do not expressly consent to direct marketing, we will not use your personal data for the direct marketing activities mentioned in this paragraph 10 of our Privacy Policy.</p>
                <p>You can request us to stop using your personal data for direct marketing purposes at any time by contacting our Data Protection Officer at hello@fltr.co.in or by using the unsubscribe facility provided in the marketing messages.</p>
                <h3>11. CHANGES TO OUR PRIVACY POLICY</h3>
                <p>We may occasionally make changes to all or part of this Privacy Policy.</p>
                <p>Any changes will become effective immediately after we post the updated Privacy Policy.</p>
                <p>If we make any changes to this Privacy Policy, we will notify you of the changes through our website, applications, or other means such as email.</p>
                <p>If we make changes to the purposes of collecting your personal data, sharing it with others, or how we use it, we will notify you in advance through our applications, website, or other means such as email and request your consent.</p>
                <p>If you choose to revoke your consent to the amendments under paragraph 11.4 above, it may affect your access to our applications and the provision of our Services.</p>
           
                <h3>12. COMPLAINT PROCESS</h3>

                <p>If you have any complaints or grievances about how we handle your personal data or our compliance with applicable privacy laws, please contact us with your complaint or grievance by writing to our Data Protection Officer at hello@fltr.co.in.</p>
                <p>To expedite the process, please indicate in the subject header of your email that it is a privacy complaint. For example, you could insert the subject header as "Privacy Complaint."</p>
                <p>We will make every effort to address any complaints or grievances promptly and fairly.</p>
                <h3>13. GENERAL</h3>
                <p>If applicable personal data privacy laws permit an organization such as ours to collect, use, or disclose your personal data without your consent, such permission granted by the law shall continue to apply.</p>
                <h3>14. ENQUIRIES</h3>
                <p>For any inquiries regarding our Privacy Policy, please contact our Data Protection Officer at hello@fltr.co.in.</p>

            </div>
        </>
    )
}
export default PrivacyPolicy