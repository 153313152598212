import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/userHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Phase 1",
      title: "Launch and Addition to PancakeSwap",
      info: [
        "This phase includes the official launch of FLTR Token and its addition to PancakeSwap, a popular decentralized exchange on the Binance Smart Chain.",
      ],
    },
    {
      roadmapTitle: "Phase 2",
      title: "Strategic Partnerships",
      info: [
        "In this phase, the FLTR Token ecosystem expands its content portfolio by forming strategic partnerships with game developers and digital content providers."
      ],
    },

    {
      roadmapTitle: "Phase 3",
      title: "Community Building",
      info: [
        "This stage focuses on strengthening the FLTR Token community and encouraging user engagement. ",
      ],
    },

    {
      roadmapTitle: "Phase 4",
      title: "Expansion of FLTR Token Ecosystem",
      info: [
        "During this phase, the ecosystem experiences growth through increased user and partner engagement. ",
      ],
    },
    {
      roadmapTitle: "Phase 5",
      title: "Global Asset Management",
      info: [
        "Innovative features incentivize users to spend more time on the platform and elevate its competitiveness.",
      ],
    },
    {
      roadmapTitle: "Phase 6",
      title: "Full Decentralization",
      info: [
        "FLTR Token achieves its ultimate goal of full decentralization and establishes a robust ecosystem. ",
      ],
    }/*,
    {
      roadmapTitle: "Phase 7",
      title: "Operational",
      info: [
        <>
          SubQuery launches its <br /> own parthian
        </>,
        "SubQuery Foundation",
      ],
    },*/
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
                FLTR Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
