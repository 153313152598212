import React, {useEffect} from "react";
import {useState} from "react";
/*import shape01 from "../../assets/img/images/fltr.png";*/
//import shape02 from "../../assets/img/banner/banner_shape02.png";
//import shape03 from "../../assets/img/images/logo.svg";
import fireIcon from "../../assets/img/images/fire.png";
/*import CountDownOne from "../CountDown/CountDownOne";*/
import CopyToClipboard from 'react-copy-to-clipboard';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Banner = (props) => {
    const [textToCopy, setTextToCopy] = useState('0x09824592081B87924b1B8371a747edfd369c06de');
    /*const [copied, setCopied] = useState(false);*/
    const handleCopy = () => {
        toast.success("Copied.")
    };

    useEffect(() => {
        setTextToCopy('0x09824592081B87924b1B8371a747edfd369c06de')
    }, []);

    return (
        <section className="banner-area banner-bg">
            {/*<div className="banner-shape-wrap">
                <img src={shape01} alt="" className="img-one"/>
                <img src={shape01} alt="" className="img-two"/>
                <img width={80} height={80} style={{marginRight: "40px"}} src={shape03} alt="" className="img-three"/>
            </div>*/}
            <ToastContainer/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="banner-content text-center">
                            <img src={fireIcon} alt=""/>
                            <h2 className="title">
                                Full Throttle Token<span> (FLTR)</span>
                            </h2>
                        </div>

                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="banner-countdown-wrap text-center">
                             <h2 className="title">Import Contract to Your Wallet</h2>
                            {/*
                            <CountDownOne/> */}

                            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                                <button style={{backgroundColor: "#F2D022", borderRadius: "15px"}}><span style={{
                                    fontSize: "25px",
                                    fontWeight: "700",
                                    color: "#294273",
                                    padding: "25px"
                                }}>{textToCopy}</span></button>
                            </CopyToClipboard>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
