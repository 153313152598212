import React, {useState, useEffect} from "react";
import axios from "axios";
import chartImg from "../../assets/img/images/chart.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";

const Sales = () => {
    const [price, setPrice] = useState(0);

    const chart_info_list = [
        "PancakeSwap Liquidity Pool: 30%",
        "Community and Targeted Sales: 10%",
        "Developer and Team Reserve: 20%",
        "Private Investors and Partners: 5%",
        "Legal & Regulation: 10%",
        "Private Sale: 5%",
        "Air Drop: 5%",
        "Investors: 15%",
    ];

    const getPrice = async () => {
        const api_url = "https://shop-api.rpd.ink/api/v1/token/fltr/price";
        const prices = await axios.get(api_url);

        return prices;
    };

    useEffect(() => {
        getPrice().then(res => {
            console.log();
            setPrice(Number(res.data.ftvt).toFixed(6));
        })
    }, []);


    return (
        <section id="sales" className="chart-area chart-bg ">
            <div className="container">
                <div className="chart-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s">
                                <img src={chartImg} alt=""/>
                                <ul>
                                    {chart_info_list.map((x, index) => (
                                        <li key={index}>{x}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-10">
                            <div
                                className="chart-content wow fadeInLeft"
                                data-wow-delay=".2s"
                            >
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <SalesTabButton
                                        title="Funding Allocation"
                                        className="active"
                                        id="funding-tab"
                                        target="#funding"
                                        ariaControls="funding"
                                        ariaSelected={true}
                                    />

                                    <SalesTabButton
                                        title="Token Allocation"
                                        className=""
                                        id="token-tab"
                                        target="#token"
                                        ariaControls="token"
                                        ariaSelected={false}
                                    />
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    <SalesTabContent
                                        className={"show active"}
                                        id="funding"
                                        ariaLabel="funding-tab"
                                        title={`1 FLTR ≈ ${price} USD`}
                                        description=" The funding allocation of FLTR Token is a strategic blueprint that drives the growth and sustainability of our ecosystem. Carefully planned and transparent, it outlines how the allocated resources will be utilized to achieve our mission. We are committed to maximizing the potential of FLTR Token, driving innovation, building strong partnerships, and rewarding our vibrant community. The funding allocation serves as a roadmap to propel FLTR Token towards becoming a leading force in the digital content and gaming landscape."
                                        link="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de"
                                    />

                                    <SalesTabContent
                                        className={""}
                                        id="token"
                                        ariaLabel="token-tab"
                                        title={`1 FLTR ≈ ${price} USD`}
                                        description=" Our token allocation strategy is carefully crafted to support the growth and sustainability of the FLTR Token ecosystem. With a focus on liquidity, community engagement, and strategic partnerships, we aim to create a vibrant and dynamic platform. The allocation ensures that all stakeholders, from users to investors, benefit from the exciting opportunities offered by FLTR Token. Together, we're reshaping the digital content landscape and empowering creators and users worldwide. Join us in this transformative journey!"
                                        link="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sales;
