import React from "react";
import { FaFacebook, FaInstagram, FaTwitter,FaYoutube} from "react-icons/fa"
const TeamOneItem = (props) => {
  return (
    <div className="team-item">
      <div className="team-thumb">
        <img src={props.item.src} alt={props.item.name} />
      </div>
      <div className="team-content">
        <h2 className="title">{props.item.name}</h2>
        <span>{props.item.designation}</span>

        <ul className="team-social">
          <li>
            <a href="/">
              <FaYoutube className="fab "/>
            </a>
          </li>
          <li>
            <a href="/">
            <FaTwitter className="fab"/>
            </a>
          </li>
          <li>
            <a href="/">
              <FaFacebook className="fab"/>
            </a>
          </li>
          <li>
            <a href="/">
            <FaInstagram className="fab"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TeamOneItem;
