import React from "react";
/*import cn from "classnames";*/
import {FaMailBulk, FaTelegram, FaTwitter} from "react-icons/fa";

const ContactOneInfoItem = (props) => {

  console.log(props)
  return (
    <div className="contact-info-item">
      <div className="icon">
        <span className="icon-background"></span>
        {props.item.icon==="fa-telegram"?<FaTelegram className="fas"/>:props.item.icon==="fa-envelope"?<FaMailBulk className="fas"/>:props.item.icon==="fa-twitter"?<FaTwitter className="fas"/>:null}
      </div>
      <div className="content">
        <p>{props.item.content}</p>
      </div>
    </div>
  );
};

export default ContactOneInfoItem;
