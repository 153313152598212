import React from "react";
import img01 from "../../assets/img/swap_logos/bscscan-logo-light.svg";
import img02 from "../../assets/img/swap_logos/coinmarketcap_white_1.svg";
import img03 from "../../assets/img/swap_logos/coin_paprika.png";
import img04 from "../../assets/img/swap_logos/coingecko.webp";
import img05 from "../../assets/img/swap_logos/pancakeswap.svg";
import img06 from "../../assets/img/swap_logos/apeswap-logo.png";
import img07 from "../../assets/img/swap_logos/1inch_color_white.png";
import img08 from "../../assets/img/swap_logos/coinranking-logo-diap.svg";


const TopPartners = () => {
  const partners_list = [
    { src: img01, title: "", link: "https://bscscan.com/token/0x09824592081B87924b1B8371a747edfd369c06de" },
    { src: img02, title: "", link: "https://coinmarketcap.com/dexscan/bsc/0x8257f9ba2daccb85227b2d1593bc04d88f0c2f55/" },
    { src: img03, title: "", link: "https://coinpaprika.com/tr/coin/fltr-full-throttle/" },
    { src: img04, title: "", link: "https://www.geckoterminal.com/bsc/pools/0x8257f9ba2daccb85227b2d1593bc04d88f0c2f55" },
    { src: img08, title: "", link: "https://coinranking.com/coin/pYcOjlmIj+fullthrottle-fltr" },
    { src: img05, title: "", link: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de" },
    { src: img06, title: "", link: "https://apeswap.finance/swap" },
    { src: img07, title: "", link: "https://app.1inch.io/#/56/simple/swap/BNB/FLTR" },
 /*    { src: img09, title: "" },
    { src: img10, title: "" }, */
  ];

  return (
    <div className="partner-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-10">
              <span className="sub-title">Review & Purchase</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="partner-wrap">
              <ul>
                {partners_list.map((item, index) => (
                  <li key={index} className={(item.src.includes("svg") || item.src.includes("webp") ? 'ps-3 pe-3': '')}>
                    <a href={item.link} target={"_blank"} rel="noreferrer"><img src={item.src} alt={item.title} /></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPartners;
