import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { useEffect } from "react";
//import WOW from "wow.js";
//import HomeTwo from "./pages/Home/HomeTwo";
//import Blog from "./pages/Blog/Blog";
//import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "../src/pages/PrivacyPolicy"
import TermsOfConditions from "./pages/TermsOfConditions"
/*import { ToastContainer, toast } from 'react-toastify';*/
function App() {
/*   useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []); */

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacypolicy"  element={ <PrivacyPolicy/> }/>
        <Route path="/Termsofconditions"  element={ <TermsOfConditions/> }/>
    {/*     <Route path="home-two" element={<HomeTwo />} /> */}
      {/*   <Route path="blog" element={<Blog />} /> */}
       {/*  <Route path="blog-details" element={<BlogDetailsPage />} /> */}
      </Routes>
    </>
  );
}

export default App;
